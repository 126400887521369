import classNames from "classnames";
import React from "react";
import { Container } from "react-bootstrap";

export const EstimatePaymentStep = ({ store, flowStore, partnerStore }) => {
   return (
      <><div className={classNames("w-450px mw-100 mx-auto")}>
         <h5>Estimate payment placeholder</h5>
      </div>
      <Container data-oid="estimate-payment-container">
         <div className={classNames("w-450px mw-100 mx-auto")}></div>
      </Container></>
   );
};

export default EstimatePaymentStep;
