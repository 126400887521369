import React from 'react';
import {Helmet} from 'react-helmet';

// inspired by this example: https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app----3l0j
const RideOctaneTitle = ({ title }) => {
    const defaultTitle = PageTitles.OCTANE_TITLE;
    return (
        <React.Fragment>
            <Helmet>
                <title>{!!title ? title : defaultTitle}</title>
            </Helmet>
        </React.Fragment>
    );
};

export const withRideOctaneTitle = (component, titleWords=[], separator=' - ') => {
    const title = titleWords.join(separator);
    return (
        <>
            {!!title ? <RideOctaneTitle title={title}/> : <RideOctaneTitle/>}
            {component}
        </>
    );
};

export const PageTitles = {
    OCTANE_TITLE: 'Octane',
    HOMEPAGE_TITLE: 'Get Instant Financing Offers',
    MODEL_AND_SUBMODEL_TITLE: 'Pricing, Features and Specs',
    MAKE_TITLE: 'UTV & ATV Models - Price, Specs & Pictures',

    VEHICLE_SELF_SELECTION: 'Apply (Select your vehicle)',
    ABOUT_YOU_TITLE: 'Apply (About You)',
    COAPPLICANT_ABOUT_YOU_TITLE: 'Apply (Co-Applicant About You)',
    OFFERS_TITLE: 'Offers',
    SSN_REQUIRED_TITLE: 'SSN Required',
    RETURN_CUSTOMER_TITLE: 'Return Customer',

    COMPLETE_MY_PAPERWORK_TITLE: 'Complete My Paperwork',
    CONSENT_FORM_TITLE: 'Consent Form',

    PAGE_NOT_FOUND_TITLE: '404 Page Not Found',
    SERVER_ERROR_TITLE: 'Server Error 500',

    LONG_PREQUAL_TITLE: 'Apply (About You)',
    LONG_PREQUAL_COAPPLICANT_TITLE: 'Apply (Co-Applicant About You)',
    LEAD_FIRST_TITLE: 'Your information',
    LEAD_FIRST_COAPPLICANT_TITLE: 'Apply (Co-Applicant information)',
    LEAD_FIRST_SUBMIT_TITLE: 'Personalize your offer',
    DEALER_SELECTOR: 'Apply (Select your dealer)',
};
