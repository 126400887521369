import {decorate, computed} from 'mobx';

import {FLOW_NAMES} from '../../enums/flows';
import {submitDataForFlowApplication} from "../utils/form-submission";
import {BaseFlowStore} from "../base-flow-store";

/**
 * LeadFirstFlowStore extends the BaseFlowStore which contains common functionality shared between flows
 */
export class LeadFirstFlowStore extends BaseFlowStore {

    constructor(props) {
        super(props);
        console.debug("LeadFirstFlowStore Initiated");
    }

    get isSubmitAppFlow() {
        return this.historyStore?.pathname.includes("/apply/submit-app") || false;
    }

    hasSubmittedRCData = false;
    get hasCompletedLeadFirstPrequalForm () {
        return !!this.applicationUuid;
    }

    /**
     * Redirects to the next step in the flow
     *
     *  - flowStepName: the name of the step to redirect to
     */
    handleStepRedirection = (flowStepName) => {
        let destinationUrl;
        switch (flowStepName) {
            case "coapplicant":
                console.info("stepName: coapplicant - redirecting to coapplicant page");
                this.historyStore.history.push(this.getPrequalUrl("/coapplicant") + `?flowUuid=${this.applicationUuid}`);
                return;

            case "dealer_selector":
                console.info("stepName: dealer_selector - redirecting to dealer selector");
                destinationUrl = this.getPrequalUrl(`/dealer/${this.applicationUuid}`);
                this.historyStore.history.push(destinationUrl);
                return;

            case "payment_calculator":
                console.info("stepName: estimate_payment - redirecting to estimate payment page");
                destinationUrl = this.getPrequalUrl(`/estimate-payment`);
                this.historyStore.history.push(destinationUrl);
                return;

            case "submit_app":
                console.info("stepName: submit_app - redirecting to app submission");
                destinationUrl = this.getPrequalUrl(`/submit-app/${this.applicationUuid}`);
                this.historyStore.history.push(destinationUrl);
                return;

            case "decisioning":
                if (this.isCoapplicantFlow) {
                    console.info("stepName: decisioning - coapp");
                    destinationUrl = this.getPrequalUrl(`/offers/${this.applicationUuid}/${this.primaryApplicantUuid}`);
                    this.historyStore.history.push(destinationUrl);
                }
                else {
                    console.info("stepName: decisioning - no coapp");
                    destinationUrl = this.getPrequalUrl(`/offers/${this.applicationUuid}`);
                    this.historyStore.history.push(destinationUrl);
                }
                return;

            default:
                const metadata = {
                    flowStepName,
                    applicationUuid: this.applicationUuid,
                    primaryApplicantUuid: this.primaryApplicantUuid,
                }
                console.error(`Unknown flow step name: ${flowStepName}, has to be implemented in handleStepRedirection`, metadata);
        }
    }

    onSubmitDealership = async (dealershipIdentifier) => {
        try{
            const response = await submitDataForFlowApplication(FLOW_NAMES.LEAD_FIRST, {
                "flow_uuid": this.applicationUuid,
                "dealership": dealershipIdentifier,
            });
            if (response.success) {
                const flowStepName = response.data['step_name'];
                this.handleStepRedirection(flowStepName);
            } else {
                const errorMessage = `Server error ${response.status} body: ${response.data}`;
                this.showServerErrorView(errorMessage);
            }
        } catch (error){
            this.showServerErrorView(error);
        }
    }
    onSubmitRCData = async (tradeInIntent, selectedLoans) => {
        try {
            const response = await submitDataForFlowApplication(FLOW_NAMES.LONG_PREQUAL, {
                "flow_uuid": this.applicationUuid,
                "selected_loans": selectedLoans,
                "trade_in_intent": tradeInIntent,
            });
            if (response.success) {
                const flowStepName = response.data['step_name'];
                this.hasSubmittedRCData = true;
                this.handleStepRedirection(flowStepName);
            } else {
                const errorMessage = `Server error ${response.status} body: ${response.data}`;
                this.showServerErrorView(errorMessage);
            }
        } catch (error){
            this.showServerErrorView(error);
        }
    }
}

decorate(LeadFirstFlowStore, {
    hasCompletedLeadFirstPrequalForm: computed,
    isSubmitAppFlow: computed,
});
