import map from "lodash.map";
import pick from 'lodash.pick';
import mapValues from "lodash.mapvalues";
import {decorate, computed, observe, observable, toJS} from 'mobx';

/**
 * @class RideOctaneStore
 *
 * Manages app state and global stores passed to mobx <Provider>
 *
 * @property isUXServer {boolean}
 * @property isUXServerRunning {boolean}
 *
 * @property assets {object}
 * @property settings {object}
 *
 * @property isReady {boolean} - ready to render component
 * @property isRendered {boolean} - component has been rendered
 *
 * @property stores {object} - collection of global stores
 */
export class RideOctaneStore {
    isUXServer = false;
    isUXServerRunning = false;

    assets = null;
    settings = null;

    stores = {};

    isRendered = false;

    /**
     * Returns true iff all stores are ready
     */
    get isReady() {
        return !map(this.stores, store => store.isReady).includes(false);
    }

    constructor(options) {
        Object.assign(this, options);
    }

    setInitialState({isUXServer, isUXServerRunning, assets, settings, stores = {}}) {
        this.isUXServer = isUXServer || false;
        this.isUXServerRunning = isUXServerRunning || false;

        this.assets = assets || null;
        this.settings = settings || null;

        this.isRendered = false;

        this.stores = mapValues(this.stores, (store, key) => {
            return store.setInitialState(Object.assign({rideOctaneStore: this}, stores[key]));
        });
        return this;
    }

    /**
     * Setup stores prior to rendering
     */
    preRenderSetup(config) {
        mapValues(this.stores, store => store.preRenderSetup(config));
        return this;
    }

    /**
     * Executes renderFn once all stores are rendered
     */
    render(renderFn) {
        if (this.isReady) {
            renderFn({rideOctaneStore: this, ...this.stores}, () => (this.isRendered = true));
        } else {
            const dispose = observe(this, 'isReady', () => {
                dispose();
                this.render(renderFn);
            });
        }
        return this;
    }

    /**
     * Returns current state (passed to the client from the server)
     */
    dehydrateState() {
        const state = toJS(this);

        delete state.isRendered;
        delete state.isUXServer;
        delete state.isUXServerRunning;
        state.settings = this.dehydrateSettings();
        state.stores = mapValues(state.stores, store => store.dehydrateState());

        return state;
    }

    dehydrateSettings() {
        // pick specific settings to send to the client
        return pick(this.settings,
            'SENTRY_RELEASE',
            'SENTRY_DSN_CLIENT',
            'GOOGLE_ANALYTICS_ID',
            'GOOGLE_TAG_MANAGER_ID',
            'INTERCOM_APP_ID',
            'BASE_API_URL',
            'BASE_UX_URL',
            'GIT_COMMIT',
            'ENVIRONMENT_KEY',
            'DEBUG_SSN_REQUIRED',
            'APP_VERSION',
            'DD_SERVICE',
        );
    }

    /**
     * Setup stores after rendering (only executed on the client)
     */
    postRenderSetup() {
        if (this.isRendered) {
            mapValues(this.stores, store => store.postRenderSetup());
        } else {
            const dispose = observe(this, 'isRendered', () => {
                dispose();
                this.postRenderSetup();
            });
        }
        return this;
    }
}

decorate(RideOctaneStore, {
    isReady: computed,
    isRendered: observable,
});
