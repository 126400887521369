import { inject, observer } from "mobx-react";
import React from "react";
import EstimatePaymentStep from "./estimate-payment-step";

export const EstimatePaymentStepContainer = ({ store, flowStore, partnerStore, ...props }) => {
   return (
      <EstimatePaymentStep
         store={store}
         flowStore={flowStore}
         partnerStore={partnerStore}
         {...props}
      />
   );
};

export default inject("store", "flowStore", "partnerStore")(observer(EstimatePaymentStepContainer));
