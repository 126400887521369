import React from 'react';
import { observer, inject } from 'mobx-react';

import { Prequal } from '../prequal';
import { LongPrequalFlow } from './long-prequal-flow';
import { LeadFirstFlow } from './lead-first-flow';
import { TwoStepPrequalFlow } from './two-step-prequal-flow/two-step-prequal-flow';

const flowMapping = {
    prequal: Prequal,
    'long-prequal': LongPrequalFlow,
    'two-step': TwoStepPrequalFlow,
    'lead-first': LeadFirstFlow,
};

// If the flow query param is invalid, defaults to old prequal flow
export const getFlowComponent = (flowName) => {
    // when flowName is not defined, has to be default to TwoStepPrequalFlow
    // for now we leave Prequal, at least until we can remove the old prequal flow entirely
    return flowName && flowMapping[flowName] ? flowMapping[flowName] : Prequal;
};

/* This file render the correct flow given a flow query parameter */
export const Flows = inject('historyStore')(
    observer(({ historyStore, ...props }) => {
        const flow = historyStore.queryParams.flow;
        const FlowComponent = getFlowComponent(flow);

        return <FlowComponent {...props} />;
    })
);
