import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {observer, inject} from "mobx-react";
import {Container, Col, Row} from "react-bootstrap";
import {Redirect} from "react-router-dom";

import copy from "./copy";
import { isE2ETest } from '../../../prequal/about-you/about-you-helpers';
import {FormHeader} from "../../../components/form-header/form-header";
import {FancySpinner} from "../../../components/spinners/fancy-spinner";
import {VehicleFinancingUnavailable} from "../../../components/vehicle-financing-unavailable";
import {Captcha} from "../../../components/captcha";
import {DisclosureBlock} from "../../../components/disclosure-block";
import {BrandingValueProp} from "../../../components/branding";
import {isInIFrame, postMessageScrollIntoView} from "../../../utils/iframe-utils";
import {LeadFirstStepStore} from "./lead-first-step-store";
import {ServerError} from "../../../components/interstitials";
import {
    CityField,
    DateOfBirthField,
    EmailField,
    EmployerDateField,
    EmployerNameField,
    EmployerPhoneNumberField,
    Street1Field,
    Street2Field,
    YearlyIncomeField,
    JobTitleField,
    MonthlyHousingPaymentField,
    PhoneNumberField,
    ResidentialDateField,
    SSNField,
    StateField,
    ZipcodeField,
    EmploymentStatusField,
    CitizenshipStatusField,
    ResidentialStatusField,
    ContactPreferenceField,
    TradeInIntentField,
    FirstNameField,
    LastNameField,
    PurchaseIntentField,
    SubmitFormButton,
    CoapplicantIntentField,
    RelationshipToPrimaryField,
    ReusePrimaryApplicantAddressField,
} from "../../../form-fields";
import { logVehicleFinancingUnavailable } from "../../../prequal/about-you/about-you-helpers";

export const LeadFirstStep = inject(
    "apiStore",
    "uxCopyStore",
    "partnerStore",
    "dealershipStore",
    "themeStore",
    "historyStore",
    "rideOctaneStore",
    "waffleStore",
    "flowStore",
    "userStore",
)(
    observer(
        class _LeadFirstStep extends React.Component {
            static propTypes = {
                accessoriesAmount: PropTypes.number,
                hasFetchedVehicle: PropTypes.bool,
                vehicleConfiguration: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    msrp: PropTypes.number.isRequired,
                    default_image: PropTypes.string.isRequired,
                }),
                isDummyVehicleEnabled: PropTypes.bool.isRequired,
                rawVehicleData: PropTypes.object,
                isCoapplicantFlow: PropTypes.bool,
            };

            captchaRef = React.createRef();
            store = new LeadFirstStepStore(this.props);

            componentDidMount() {
                window.scrollTo(0, 0);
                if (this.captchaRef && this.captchaRef.current) {
                    this.captchaRef.current.reset();
                }
            }

            launchCaptcha = () => {
                const email = this.store.fieldStates.email.value;
                if (isInIFrame()) {
                    postMessageScrollIntoView(window.top);
                } else {
                    window.scrollTo(0, 0);
                }
                // Validation for e2e tests to bypass captcha
                if (isE2ETest(this.store.rideOctaneStore.settings.ENVIRONMENT_KEY, email)) {
                    this.store.submitForm(
                        null,
                        this.props.vehicleConfiguration,
                        this.props.rawVehicleData,
                        this.props.flowStore.updateApplicationUuid,
                        this.props.flowStore.updateApplicantUuids,
                    )
                } else {
                    this.captchaRef.current.execute();
                }
            };

            render() {
                if(this.store.showServerError) {
                    return (
                        <Container data-oid="lead-first-step-error-page">
                            <ServerError origin="lead-first-step" errorMessage={this.store.serverErrorMessage}/>
                        </Container>
                    );
                }

                // If haven't fetched the vehicle and we need the vehicle for the VehicleHeader display the fancy loading spinner
                if (this.store.headerType === "VehicleHeader" && !this.props.hasFetchedVehicle){
                    return (
                        <Container data-oid="lead-first-loading">
                            <FancySpinner className="d-block mx-auto my-5" />
                        </Container>
                    );
                }

                // If the vehicle has been fetched and there is no vehicle configuration and this partner doesn't decision on dummmy vehicles
                if (this.props.hasFetchedVehicle && !this.props.vehicleConfiguration && !this.props.isDummyVehicleEnabled) {
                    // For certain partners (BRP), to give the customer a better user experience, we don't render the
                    // vehicle not found view and instead just redirect them to the homepage to select the vehicle through us
                    if (!this.store.showVehicleFinancingUnavailable) {
                        return <Redirect to="/" />;
                    }
                    logVehicleFinancingUnavailable(
                        this.props.partnerStore.partnerIdentifier,
                        this.props.hasFetchedVehicle,
                        this.props.vehicleConfiguration,
                        this.props.isDummyVehicleEnabled
                        );
                    return (
                        <Container data-oid="lead-first-unavailable">
                            <VehicleFinancingUnavailable />
                        </Container>
                    );
                }

                if (!this.props.flowStore.hasCompletedLeadFirstPrequalForm) {
                    return (
                        <Container data-oid="lead-first-container" key="primary_applicant_flow">
                            <div className={classNames("w-450px mw-100 mx-auto")}>
                                    <FormHeader
                                        headerType={this.store.headerType}
                                        vehicleConfiguration={this.props.vehicleConfiguration}
                                        accessoriesAmount={this.props.accessoriesAmount}
                                        title={copy.title}
                                        isCoapplicantFlow={this.props.isCoapplicantFlow}
                                    />
    
                                <form id="lead-first-form">
                                    <Row>
                                        <Col xs={6}>
                                            <FirstNameField
                                                controlId={"first_name"}
                                                value={this.store.fieldStates["first_name"].value}
                                                error={this.store.fieldStates["first_name"].error}
                                                onChange={(event) => this.store.updateFieldValue(event, "first_name")}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <LastNameField
                                                controlId={"last_name"}
                                                value={this.store.fieldStates["last_name"].value}
                                                error={this.store.fieldStates["last_name"].error}
                                                onChange={(event) => this.store.updateFieldValue(event, "last_name")}
                                            />
                                        </Col>
                                    </Row>
    
                                    <EmailField
                                        controlId={"email"}
                                        value={this.store.fieldStates["email"].value}
                                        error={this.store.fieldStates["email"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "email")}
                                    />
                                    <ContactPreferenceField
                                        controlId={"contact_preference"}
                                        value={this.store.fieldStates["contact_preference"].value}
                                        error={this.store.fieldStates["contact_preference"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "contact_preference")}
                                        partnerStore={this.store.partnerStore}
                                    />
    
                                    <PhoneNumberField
                                        controlId={"phone_number"}
                                        value={this.store.fieldStates["phone_number"].value}
                                        error={this.store.fieldStates["phone_number"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "phone_number")}
                                    />
    
                                    <PurchaseIntentField
                                        controlId={"purchase_intent"}
                                        value={this.store.fieldStates["purchase_intent"].value}
                                        error={this.store.fieldStates["purchase_intent"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "purchase_intent")}
                                        partnerStore={this.store.partnerStore}
                                        apiStore={this.store.apiStore}
                                    />
                                    <div>
                                        <BrandingValueProp
                                            showPoweredByOctane={this.store.showPoweredByOctane}
                                            showDesktopLayout={!!this.props.partnerStore.hasMultiColumnDisplay}
                                            showBrandingForCampingWorld={this.props.partnerStore.isRV}
                                        />
                                        <DisclosureBlock
                                            id="text-consent"
                                            showCheckBox
                                            disclosureText={copy.text_consent.labelContent}
                                            onChange={this.store.updateTextConsent}
                                            isChecked={this.store.textConsent}
                                        />

                                        <Captcha
                                            captchaRef={this.captchaRef}
                                            onClickContinue={(captcha) =>
                                            this.store.submitForm(
                                                captcha,
                                                this.props.vehicleConfiguration,
                                                this.props.rawVehicleData,
                                                this.props.flowStore.updateApplicationUuid,
                                                this.props.flowStore.updateApplicantUuids,
                                            )}
                                        />

                                        <SubmitFormButton
                                            canContinue={
                                                this.props.hasFetchedVehicle
                                                && this.store.isFormValid
                                                && this.store.textConsent
                                            }
                                            oid='cta-lead-first'
                                            onClick={this.launchCaptcha}
                                            continueButtonLabel={this.store.continueButtonLabel}
                                            partnerStore={this.store.partnerStore}
                                        />
                                    </div>
                                </form>
                            </div>
                        </Container>
                    );
                }
                if (this.props.flowStore.hasCompletedLeadFirstPrequalForm && !this.props.isCoapplicantFlow) {
                    return (
                        <Container data-oid="lead-first-container" key="primary_applicant_flow">
                            <div className={classNames("w-450px mw-100 mx-auto")}>
                                    <FormHeader
                                        headerType={this.store.headerType}
                                        vehicleConfiguration={this.props.vehicleConfiguration}
                                        accessoriesAmount={this.props.accessoriesAmount}
                                        title={copy.title}
                                        isCoapplicantFlow={this.props.isCoapplicantFlow}
                                    />

                                <form id="lead-first-form">
                                    <DateOfBirthField
                                        controlId={"date_of_birth"}
                                        value={this.store.fieldStates["date_of_birth"].value}
                                        error={this.store.fieldStates["date_of_birth"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "date_of_birth")}
                                    />

                                    <Street1Field
                                        controlId={"street1"}
                                        value={this.store.fieldStates["street1"].value}
                                        error={this.store.fieldStates["street1"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "street1")}
                                        required={!this.store.isReusingPrimaryAddress}
                                        disabled={this.store.isReusingPrimaryAddress}
                                    />

                                    <Street2Field
                                        controlId={"street2"}
                                        value={this.store.fieldStates["street2"].value}
                                        error={this.store.fieldStates["street2"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "street2")}
                                        disabled={this.store.isReusingPrimaryAddress}
                                    />

                                    <CityField
                                        controlId={"city"}
                                        value={this.store.fieldStates["city"].value}
                                        error={this.store.fieldStates["city"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "city")}
                                        required={!this.store.isReusingPrimaryAddress}
                                        disabled={this.store.isReusingPrimaryAddress}
                                    />

                                    <Row>
                                        <Col xs={6}>
                                            <StateField
                                                controlId={"state"}
                                                error={this.store.fieldStates["state"].error}
                                                value={this.store.fieldStates["state"].value}
                                                onChange={(event) => this.store.updateFieldValue(event, "state")}
                                                required={!this.store.isReusingPrimaryAddress}
                                                disabled={this.store.isReusingPrimaryAddress}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <ZipcodeField
                                                controlId={"zip_code"}
                                                value={this.store.fieldStates["zip_code"].value}
                                                error={this.store.fieldStates["zip_code"].error}
                                                onChange={(event) => this.store.updateFieldValue(event, "zip_code")}
                                                required={!this.store.isReusingPrimaryAddress}
                                                disabled={this.store.isReusingPrimaryAddress}
                                            />
                                        </Col>
                                    </Row>

                                    <ResidentialStatusField
                                        controlId={"residential_status"}
                                        value={this.store.fieldStates["residential_status"].value}
                                        error={this.store.fieldStates["residential_status"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "residential_status")}
                                    />

                                    <ResidentialDateField
                                        controlId={"residential_date"}
                                        value={this.store.fieldStates["residential_date"].value}
                                        error={this.store.fieldStates["residential_date"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "residential_date")}
                                    />

                                    <EmploymentStatusField
                                        controlId={"employment_status"}
                                        value={this.store.fieldStates["employment_status"].value}
                                        error={this.store.fieldStates["employment_status"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "employment_status")}
                                    />

                                    <YearlyIncomeField
                                        controlId={"yearly_income"}
                                        value={this.store.fieldStates["yearly_income"].value}
                                        error={this.store.fieldStates["yearly_income"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "yearly_income")}
                                        toolTipText={"Note"}
                                        toolTipMsg={"Alimony, child, support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation or for account maintenance."}
                                    />

                                    <CitizenshipStatusField
                                        controlId={"citizenship_status"}
                                        value={this.store.fieldStates["citizenship_status"].value}
                                        error={this.store.fieldStates["citizenship_status"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "citizenship_status")}
                                    />

                                    <MonthlyHousingPaymentField
                                        controlId={"monthly_housing_payment"}
                                        value={this.store.fieldStates["monthly_housing_payment"].value}
                                        error={this.store.fieldStates["monthly_housing_payment"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "monthly_housing_payment")}
                                    />

                                    <EmployerNameField
                                        controlId={"employer_name"}
                                        value={this.store.fieldStates["employer_name"].value}
                                        error={this.store.fieldStates["employer_name"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "employer_name")}
                                    />

                                    <EmployerPhoneNumberField
                                        controlId={"employer_phone"}
                                        value={this.store.fieldStates["employer_phone"].value}
                                        error={this.store.fieldStates["employer_phone"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "employer_phone")}
                                    />

                                    <JobTitleField
                                        controlId={"job_title"}
                                        value={this.store.fieldStates["job_title"].value}
                                        error={this.store.fieldStates["job_title"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "job_title")}
                                    />

                                    <EmployerDateField
                                        controlId={"employer_date"}
                                        value={this.store.fieldStates["employer_date"].value}
                                        error={this.store.fieldStates["employer_date"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "employer_date")}
                                    />

                                    <SSNField
                                        controlId={"social_security_number"}
                                        value={this.store.fieldStates["social_security_number"].value}
                                        error={this.store.fieldStates["social_security_number"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "social_security_number")}
                                    />

                                    <TradeInIntentField
                                        controlId={"trade_in_intent"}
                                        value={this.store.fieldStates["trade_in_intent"].value}
                                        error={this.store.fieldStates["trade_in_intent"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "trade_in_intent")}
                                        partnerStore={this.store.partnerStore}
                                    />

                                    {this.store.showCoapplicantIntentField &&
                                        <CoapplicantIntentField
                                            controlId={"coapplicant_intent"}
                                            value={this.store.fieldStates["coapplicant_intent"].value}
                                            error={this.store.fieldStates["coapplicant_intent"].error}
                                            onChange={(event) => this.store.updateFieldValue(event, "coapplicant_intent")}
                                        />
                                    }

                                    <div>
                                        <BrandingValueProp
                                            showPoweredByOctane={this.store.showPoweredByOctane}
                                            showDesktopLayout={!!this.props.partnerStore.hasMultiColumnDisplay}
                                            showBrandingForCampingWorld={this.props.partnerStore.isRV}
                                            showMiniProp
                                        />

                                        <DisclosureBlock
                                            id="consent"
                                            showCheckBox
                                            showRuleLine
                                            disclosureText={this.store.prequalDisclosure}
                                            onChange={this.store.updatePrequalDisclosureConsent}
                                            isChecked={this.store.prequalDisclosureConsent}
                                        />
                                        <Captcha
                                            captchaRef={this.captchaRef}
                                            onClickContinue={(captcha) =>
                                            this.store.submitForm(
                                                captcha,
                                                this.props.vehicleConfiguration,
                                                this.props.rawVehicleData,
                                                this.props.flowStore.updateApplicationUuid,
                                                this.props.flowStore.updateApplicantUuids,
                                            )}
                                        />

                                        <SubmitFormButton
                                            canContinue={
                                                this.props.hasFetchedVehicle
                                                && this.store.prequalDisclosureConsent
                                                && this.store.isFormValid
                                            }
                                            oid='cta-lead-first'
                                            onClick={this.launchCaptcha}
                                            continueButtonLabel={this.store.continueButtonLabel}
                                            partnerStore={this.store.partnerStore}
                                        />
                                    </div>
                                </form>
                            </div>
                        </Container>
                    )
                }
                if (this.props.flowStore.hasCompletedLeadFirstPrequalForm && this.props.isCoapplicantFlow) {
                    return (
                        <Container data-oid="lead-first-container" key="primary_applicant_flow">
                            <div className={classNames("w-450px mw-100 mx-auto")}>
                                    <FormHeader
                                        headerType={this.store.headerType}
                                        vehicleConfiguration={this.props.vehicleConfiguration}
                                        accessoriesAmount={this.props.accessoriesAmount}
                                        title={copy.title}
                                        isCoapplicantFlow={this.props.isCoapplicantFlow}
                                    />

                                <form id="lead-first-form">
                                    <RelationshipToPrimaryField
                                        controlId={"relationship_to_primary"}
                                        value={this.store.fieldStates["relationship_to_primary"].value}
                                        error={this.store.fieldStates["relationship_to_primary"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "relationship_to_primary")}
                                    />

                                    <Row>
                                        <Col xs={6}>
                                            <FirstNameField
                                                controlId={"first_name"}
                                                value={this.store.fieldStates["first_name"].value}
                                                error={this.store.fieldStates["first_name"].error}
                                                onChange={(event) => this.store.updateFieldValue(event, "first_name")}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <LastNameField
                                                controlId={"last_name"}
                                                value={this.store.fieldStates["last_name"].value}
                                                error={this.store.fieldStates["last_name"].error}
                                                onChange={(event) => this.store.updateFieldValue(event, "last_name")}
                                            />
                                        </Col>
                                    </Row>

                                    <EmailField
                                        controlId={"email"}
                                        value={this.store.fieldStates["email"].value}
                                        error={this.store.fieldStates["email"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "email")}
                                    />

                                    <DateOfBirthField
                                        controlId={"date_of_birth"}
                                        value={this.store.fieldStates["date_of_birth"].value}
                                        error={this.store.fieldStates["date_of_birth"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "date_of_birth")}
                                    />

                                    {this.store.primaryApplicantAddressData &&
                                        <ReusePrimaryApplicantAddressField
                                            controlId={"reuse_primary_address"}
                                            value={this.store.fieldStates["reuse_primary_address"].value}
                                            error={this.store.fieldStates["reuse_primary_address"].error}
                                            onChange={(event) => this.store.onReusePrimaryAddressChange(event)}
                                        />
                                    }

                                    <Street1Field
                                        controlId={"street1"}
                                        value={this.store.fieldStates["street1"].value}
                                        error={this.store.fieldStates["street1"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "street1")}
                                        required={!this.store.isReusingPrimaryAddress}
                                        disabled={this.store.isReusingPrimaryAddress}
                                    />

                                    <Street2Field
                                        controlId={"street2"}
                                        value={this.store.fieldStates["street2"].value}
                                        error={this.store.fieldStates["street2"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "street2")}
                                        disabled={this.store.isReusingPrimaryAddress}
                                    />

                                    <CityField
                                        controlId={"city"}
                                        value={this.store.fieldStates["city"].value}
                                        error={this.store.fieldStates["city"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "city")}
                                        required={!this.store.isReusingPrimaryAddress}
                                        disabled={this.store.isReusingPrimaryAddress}
                                    />

                                    <Row>
                                        <Col xs={6}>
                                            <StateField
                                                controlId={"state"}
                                                error={this.store.fieldStates["state"].error}
                                                value={this.store.fieldStates["state"].value}
                                                onChange={(event) => this.store.updateFieldValue(event, "state")}
                                                required={!this.store.isReusingPrimaryAddress}
                                                disabled={this.store.isReusingPrimaryAddress}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <ZipcodeField
                                                controlId={"zip_code"}
                                                value={this.store.fieldStates["zip_code"].value}
                                                error={this.store.fieldStates["zip_code"].error}
                                                onChange={(event) => this.store.updateFieldValue(event, "zip_code")}
                                                required={!this.store.isReusingPrimaryAddress}
                                                disabled={this.store.isReusingPrimaryAddress}
                                            />
                                        </Col>
                                    </Row>

                                    <ResidentialStatusField
                                        controlId={"residential_status"}
                                        value={this.store.fieldStates["residential_status"].value}
                                        error={this.store.fieldStates["residential_status"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "residential_status")}
                                    />

                                    <ResidentialDateField
                                        controlId={"residential_date"}
                                        value={this.store.fieldStates["residential_date"].value}
                                        error={this.store.fieldStates["residential_date"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "residential_date")}
                                    />

                                    <EmploymentStatusField
                                        controlId={"employment_status"}
                                        value={this.store.fieldStates["employment_status"].value}
                                        error={this.store.fieldStates["employment_status"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "employment_status")}
                                    />

                                    <YearlyIncomeField
                                        controlId={"yearly_income"}
                                        value={this.store.fieldStates["yearly_income"].value}
                                        error={this.store.fieldStates["yearly_income"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "yearly_income")}
                                        toolTipText={"Note"}
                                        toolTipMsg={"Alimony, child, support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation or for account maintenance."}
                                    />
                                    <PhoneNumberField
                                        controlId={"phone_number"}
                                        value={this.store.fieldStates["phone_number"].value}
                                        error={this.store.fieldStates["phone_number"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "phone_number")}
                                    />

                                    <CitizenshipStatusField
                                        controlId={"citizenship_status"}
                                        value={this.store.fieldStates["citizenship_status"].value}
                                        error={this.store.fieldStates["citizenship_status"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "citizenship_status")}
                                    />

                                    <MonthlyHousingPaymentField
                                        controlId={"monthly_housing_payment"}
                                        value={this.store.fieldStates["monthly_housing_payment"].value}
                                        error={this.store.fieldStates["monthly_housing_payment"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "monthly_housing_payment")}
                                    />

                                    <EmployerNameField
                                        controlId={"employer_name"}
                                        value={this.store.fieldStates["employer_name"].value}
                                        error={this.store.fieldStates["employer_name"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "employer_name")}
                                    />

                                    <EmployerPhoneNumberField
                                        controlId={"employer_phone"}
                                        value={this.store.fieldStates["employer_phone"].value}
                                        error={this.store.fieldStates["employer_phone"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "employer_phone")}
                                    />

                                    <JobTitleField
                                        controlId={"job_title"}
                                        value={this.store.fieldStates["job_title"].value}
                                        error={this.store.fieldStates["job_title"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "job_title")}
                                    />

                                    <EmployerDateField
                                        controlId={"employer_date"}
                                        value={this.store.fieldStates["employer_date"].value}
                                        error={this.store.fieldStates["employer_date"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "employer_date")}
                                    />

                                    <SSNField
                                        controlId={"social_security_number"}
                                        value={this.store.fieldStates["social_security_number"].value}
                                        error={this.store.fieldStates["social_security_number"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "social_security_number")}
                                    />

                                    <TradeInIntentField
                                        controlId={"trade_in_intent"}
                                        value={this.store.fieldStates["trade_in_intent"].value}
                                        error={this.store.fieldStates["trade_in_intent"].error}
                                        onChange={(event) => this.store.updateFieldValue(event, "trade_in_intent")}
                                        partnerStore={this.store.partnerStore}
                                    />
                                    <div>
                                        <BrandingValueProp
                                            showPoweredByOctane={this.store.showPoweredByOctane}
                                            showDesktopLayout={!!this.props.partnerStore.hasMultiColumnDisplay}
                                            showBrandingForCampingWorld={this.props.partnerStore.isRV}
                                            showMiniProp
                                        />

                                        <DisclosureBlock
                                            id="consent"
                                            showCheckBox
                                            showRuleLine
                                            disclosureText={this.store.prequalDisclosure}
                                            onChange={this.store.updatePrequalDisclosureConsent}
                                            isChecked={this.store.prequalDisclosureConsent}
                                        />

                                        <Captcha
                                            captchaRef={this.captchaRef}
                                            onClickContinue={(captcha) =>
                                            this.store.submitForm(
                                                captcha,
                                                this.props.vehicleConfiguration,
                                                this.props.rawVehicleData,
                                                this.props.flowStore.updateApplicationUuid,
                                                this.props.flowStore.updateApplicantUuids,
                                            )}
                                        />

                                        <SubmitFormButton
                                            canContinue={
                                                this.props.hasFetchedVehicle
                                                && this.store.prequalDisclosureConsent
                                                && this.store.isFormValid
                                            }
                                            oid='cta-lead-first'
                                            onClick={this.launchCaptcha}
                                            continueButtonLabel={this.store.continueButtonLabel}
                                            partnerStore={this.store.partnerStore}
                                        />
                                    </div>
                                </form>
                            </div>
                        </Container>
                    );
                }
                return (
                    <Container data-oid="lead-first-step-error-page">
                        <ServerError origin="lead-first-step" errorMessage={this.store.serverErrorMessage}/>
                    </Container>
                );
            }
        },
    ),
);
