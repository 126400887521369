import React from "react";
import PropTypes from "prop-types";

import * as SparkIcons from "@octane/spark";

export const DynamicIcon = ({ iconName, ...props }) => {
   try {
      // Uppercase first letter
      const formattedName = iconName.charAt(0).toUpperCase() + iconName.slice(1);
      const componentName = `${formattedName}Icon`;

      if (!SparkIcons[componentName]) {
         console.warn(`Icon component "${componentName}" not found in SparkIcons`);
         return null;
      }

      const IconComponent = SparkIcons[componentName];

      return <IconComponent {...props} />;
   } catch (error) {
      return null;
   }
};

DynamicIcon.propTypes = {
   iconName: PropTypes.string.isRequired,
};
