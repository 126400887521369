import Ajv from 'ajv';

import {getStates} from "./get-states";
import {cleanNumberString} from "./number-utils";
import {employmentStatuses} from "../enums/employmentStatus";
import {relationshipToPrimary} from "../enums/relationshipToPrimary";
import {apiResidentialStatuses} from "../enums/residentialStatus";
import {getNumberOfDaysInMonth} from "./date-utils";
import {isValidPhoneNumber} from 'libphonenumber-js';

export const validationExpressions = {
    // matches on a single word (alphanumeric characters & underscore)
    single_word: /^\w+$/,

    // matches on a single line of text. (any character except linebreaks)
    single_line_text: /^.+$/,

    // optionally matches on any characters as long as they are on one line.
    optional_single_line_text: /^.*$/,

    // matches on alphanumeric characters (& underscores) on one to multiple lines.
    multiple_line_text: /^[\w\s]+$/,

    // matches on an integer value.
    integer: /^\d+$/,

    // matches on an email address.
    email: /^.+@.+\.[a-zA-Z]+$/,

    // matches on a zip code.
    zip_code: /^\d{5}?$/,

    // matches a 9 digit integer that doesn't begin with a 9 (presumably a ssn)
    ssn: /^[0-8]{1}\d{8}$/,

    // matches alphabetic (name fields)
    name: /^[A-Za-z]+[A-Za-z\-'.\s]*$/,

    // matches at least one letter (a-z) for updated address field check, no exclamation points allowed
    street1: /^[^!]*[A-Za-z]+[^!]*$/,

    // matches on if it is made up of letters, spaces, and/or hyphens and nothing else
    city: /^[\u00C0-\u00FFa-zA-Z- ]+$/,

};

export const reveo_payload_schema = {
    type: 'object',
    properties: {
        partner_lead_id: {type: ["string"]},
        contact_preference: {type: ["string", "null"]},
        customer: {
            type: "object",
            properties: {
                first_name: {type: ["string", "null"]},
                last_name: {type: ["string", "null"]},
                email: {type: ["string", "null"]},
                phone_number: {type: ["string", "null"]},
                street1: {type: ["string", "null"]},
                city: {type: ["string", "null"]},
                state: {type: ["string", "null"]},
                zip_code: {type: ["string", "integer", "null"]},
                purchase_intent: {type: ["string", "null"]},
                location: {type: ["string", "null"]},
            },
        },
        vehicle: {
            type: "object",
            properties: {
                uuid: {type: ["string", "null"]},
                reveo_id: {type: ["string", "null"]},
                year: {type: ["integer", "null"]},
                make: {type: ["string", "null"]},
                model: {type: ["string", "null"]},
                trim: {type: ["string", "null"]},
                type: {type: ["string", "null"]},
                price: {type: ["number", "null"]},
                stock_number: {type: ["string", "null"]},
                vin: {type: ["string", "null"]},
                condition: {type: ["string", "null"]},
                location: {type: ["string", "null"]},
            },
        },
    },
    required: ['partner_lead_id'],
    additionalProperties: false,
};

export const rv_payload_schema = {
    type: 'object',
    properties: {
        rv_data: {
            type: "object",
            properties: {
                year: {type: ["integer", "null"]},
                oem: {type: ["string", "null"]},
                make: {type: ["string", "null"]},
                model: {type: ["string", "null"]},
                rv_type: {type: ["string", "null"]}, // see RVClassType in octane repo
                price: {type: ["number", "null"]},
                prices: {
                    sales_price: {type: ["number", "null"]},
                    msrp: {type: ["number", "null"]},
                    unlocked_price: {type: ["number", "null"]},
                },
                condition: {type: ["string", "null"]}, // see AssetCondition in octane repo
                location: {type: ["string", "null"]},
            },
        },
        customer: {
            type: "object",
            properties: {
                first_name: {type: ["string", "null"]},
                last_name: {type: ["string", "null"]},
                email: {type: ["string", "null"]},
                date_of_birth: {
                    type: ["object", "null"],
                    properties: {
                        year: {type: ["number", "null"]},
                        month: {type: ["number", "null"]},
                        day: {type: ["number", "null"]},
                    },
                },
                street1: {type: ["string", "null"]},
                street2: {type: ["string", "null"]},
                city: {type: ["string", "null"]},
                state: {type: ["string", "null"]},
                zip_code: {type: ["string", "integer", "null"]},
                residential_status: {type: ["string", "null"]},
                residential_date: {
                    type: ["object", "null"],
                    properties: {
                        year: {type: ["number", "null"]},
                        month: {type: ["number", "null"]},
                        day: {type: ["number", "null"]},
                    },
                },
                yearly_income: {type: ["string", "null"]},
                employment_status: {type: ["string", "null"]},
                trade_in_intent: {type: ["string", "null"]},
                coapplicant_intent: {type: ["boolean", "null"]},
                phone_number: {type: ["string", "null"]},
                purchase_intent: {type: ["string", "null"]},
                ssn: {type: ["string", "null"]},
            },
        },
        coapplicant: {
            type: "object",
            properties: {
                relationship_to_primary: {type: ["string", "null"]},
                coapplicant_first_name: {type: ["string", "null"]},
                coapplicant_last_name: {type: ["string", "null"]},
                coapplicant_email: {type: ["string", "null"]},
                coapplicant_date_of_birth: {
                    type: ["object", "null"],
                    properties: {
                        year: {type: ["number", "null"]},
                        month: {type: ["number", "null"]},
                        day: {type: ["number", "null"]},
                    },
                },
                coapplicant_street1: {type: ["string", "null"]},
                coapplicant_street2: {type: ["string", "null"]},
                coapplicant_city: {type: ["string", "null"]},
                coapplicant_state: {type: ["string", "null"]},
                coapplicant_zip_code: {type: ["string", "integer", "null"]},
                coapplicant_residential_status: {type: ["string", "null"]},
                coapplicant_residential_date: {
                    type: ["object", "null"],
                    properties: {
                        year: {type: ["number", "null"]},
                        month: {type: ["number", "null"]},
                        day: {type: ["number", "null"]},
                    },
                },
                coapplicant_yearly_income: {type: ["string", "null"]},
                coapplicant_employment_status: {type: ["string", "null"]},
                coapplicant_phone_number: {type: ["string", "null"]},
                coapplicant_ssn: {type: ["string", "null"]},
            }
        },
        contact_preference: {type: ["string", "null"]},
        token_id: {type: ["string", "null"]},
    },
    additionalProperties: false,
};

export const raw_vehicle_data_schema = {
    type: 'object',
    properties: {
        raw_type: {type: ["string"]},
        raw_condition: {type: ["string"]},
        raw_make: {type: ["string"]},
        raw_model: {type: ["string", "null"]},
        raw_year: {type: ["string"]},
        raw_price: {type: ["string"]},
        raw_subcategory: {type: ["string", "null"]},
        raw_engines: {type: ["string", "null"]},
        raw_has_trailer: {type: ["string", "null"]},
    },
    required: [
        'raw_type',
        'raw_condition',
        'raw_make',
        'raw_year',
        'raw_price',
    ],
    additionalProperties: false,
}

export const vehicle_payload_schema = {
    type: 'object',
    properties: {
        product_category: {type: ["string", "null"]},
        product_color: {type: ["string", "null"]},
        product_condition: {type: ["string", "null"]},
        product_id: {type: ["string", "null"]},
        product_make: {type: ["string", "null"]},
        product_model: {type: ["string", "null"]},
        product_on_sale: {type: ["boolean"]},
        // We use number for the type here because price can also be set to NaN
        product_original_price: {type: ["number", "null"]},
        product_price: {type: ["number", "null"]},
        product_stock_number: {type: ["string", "null"]},
        product_subcategory: {type: ["string", "null"]},
        product_origin_url: {type: ["string", "null"]},
        product_vin: {type: ["string", "null"]},
        product_year: {type: ["string", "null"]},
        product_location: {type: ["string", "null"]},
        product_dealer_id: {type: ["string", "null"]},
        product_ad_id: {type: ["number", "null"]},
    },
    required: [
        'product_category',
        'product_color',
        'product_condition',
        'product_id',
        'product_make',
        'product_model',
        'product_on_sale',
        'product_original_price',
        'product_price',
        'product_stock_number',
        'product_subcategory',
        // 'product_origin_url', TODO make required after cached legacy widget schema expires
        'product_vin',
        'product_year',
    ],
    additionalProperties: false,
};

/**
 * Validates that the provided value consists of a single word.
 * @param {string} value: Value of the field to be validated.
 */
export function validateSingleWord(value) {
    if (!value || typeof value !== "string"){
        return false;
    }
    return !!value.match(validationExpressions['single_word']);
}

/**
 * Validates that the provided value consists of one line of text.
 * @param {string} value: Value of the field to be validated.
 */
export function validateSingleLine(value) {
    if (!value || typeof value !== "string"){
        return false;
    }
    return !!value.match(validationExpressions['single_line_text']);
}

/**
 * Validates that the provided value is either empty or consists of one line.
 * @param {string} value: Value of the field to be validated.
 */
export function validateOptionalSingleLine(value) {
    if (!value){
        return true;
    } else if (typeof value !== "string"){
        return false;
    }
    return !!value.match(validationExpressions['optional_single_line_text']);
}

/**
 * Validates that the provided value is an integer.
 * @param {string} value: Value of the field to be validated.
 * @param min (optional) a valid value is >= min
 * @param max (optional) a valid value is <= max
 */
export function validateIntegerString(value, min = null, max = null) {

    if (!value || typeof value !== "string"){
        return false;
    }

    // validate whether or not value is an integer
    const isInteger = !!value.match(validationExpressions['integer']);
    if (!isInteger){
        return false;
    }

    // validate min and max if specified
    if (!!min && value < min){
        return false;
    }
    if (!!max && max < value){
        return false;
    }
    return true;
}

/**
 * Validates that the provided value follows the valid email address format.
 * @param {string} value: Value of the field to be validated.
 */
export function validateEmail(value) {
    if (!value || typeof value !== "string"){
        return false;
    }
    return !!value.match(validationExpressions['email']);
}

/**
 * Validates that the provided value follows the valid zip code format.
 * @param {string} value: Value of the field to be validated.
 */
export function validateZipcode(value) {
    if (!value || typeof value !== "string"){
        return false;
    }
    return !!value.match(validationExpressions['zip_code']);
}

/**
 * Validates that the provided value follows the valid phone number format.
 * @param {string} value: Value of the field to be validated.
 */
export function validatePhone(value) {
    if (!value || typeof value !== "string"){
        return false;
    }
    value = cleanNumberString(value);
    //all numbers are assumed to be domestic US, so we prepend the country code
    value = "+1" + value;
    return isValidPhoneNumber(value);
}

/**
 * Validates that the provided value follows the valid ssn number format.
 * @param {string} value: Value of the field to be validated.
 */
export function validateSSN(value) {
    if (!value || typeof value !== "string"){
        return false;
    }
    value = cleanNumberString(value);
    return !!value.match(validationExpressions['ssn']);
}

/**
 * Validates that the provided value follows the valid name format.
 * @param {string} value: Value of the field to be validated.
 */
export function validateName(value) {
    if (!value || typeof value !== "string"){
        return false;
    }

    return !!value.match(validationExpressions['name']);
}

/**
 * Validates that the provided value for address (first field) contains at least one letter (a-z).
 * @param {string} value: Value of the field to be validated.
 */
 export function validateStreet1(value) {
    if (!value || typeof value !== "string"){
        return false;
    }
    //check to make sure there is no exclamation point
    if (value.includes("!")) {
        return false;
    }
    return !!value.match(validationExpressions['street1']);
}

/**
 * Validates that the provided value for city contains letters, spaces, and/or hyphens and nothing else.
 * @param {string} value: Value of the field to be validated.
 */
 export function validateCity(value) {
    if (!value || typeof value !== "string"){
        return false;
    }
    return !!value.match(validationExpressions['city']);
}

/**
 * Checks that value is a valid non-null value for state.
 * @param value
 */
export function validateState(value) {
    if (!value || typeof value !== "string"){
        return false;
    }
    return value && getStates().includes(value);
}

/**
 * Checks that value is a valid non-null value for employment_status.
 * @param value
 */
export function validateEmploymentStatus(value) {
    if (!value || typeof value !== "string") {
        return false;
    }
    return !!employmentStatuses.find(({id}) => (id === value));
}

/**
 * Checks that value is a valid non-null value for residential_status.
 * @param value
 */
export function validateResidentialStatus(value) {
    if (!value || typeof value !== "string") {
        return false;
    }
    return Object.values(apiResidentialStatuses).includes(value);
}

/**
 * Checks that value is a valid non-null value for purchase_intent, returns true if string.
 * @param value
 * @param purchaseIntentOptions
 */
export function validatePurchaseIntent(value) {
    if (!value || typeof value !== "string") {
        return false;
    }
    return true;
}

/**
 * Checks that the value is a valid date
 * @param {object} value
 *  {day: {number}, month: {number}, year: {number}}
 * @returns {boolean}
 */
export function validateDate(value) {
    if (!value) {
        return false;
    }
    if (!value.year) {
        return false;
    }
    if (!value.month || !(parseInt(value.month) >= 1) || !(parseInt(value.month) <= 12)) {
        return false;
    }
    return value.day <= getNumberOfDaysInMonth(value.month, value.year);
}

/**
 * Checks that the value is a valid month and year that is in the past
 * @param {object} value
 *  {day: {number}, month: {number|string}, year: {number}}
 * @returns {boolean}
 */
export function validatePastMonthAndYear(value) {
    if (!value) {
        return false;
    }
    if (!value.year) {
        return false;
    }
    if (!value.month || !(parseInt(value.month) >= 1) || !(parseInt(value.month) <= 12)) {
        return false;
    }

    const today = new Date(Date.now());
    const date = new Date(
        parseInt(value.year),
        parseInt(value.month)-1, // subtract 1 because dates are zero indexed
    );

    return date <= today;
}

/**
 * Checks that the value is a valid dob and that the applicant is at least 13 yo
 * @param {object} value
 *  {day: {number}, month: {number|string}, year: {number}}
 * @returns {boolean}
 */
export function validateDateOfBirth(value) {
    const today = new Date(Date.now());
    if (!validateDate(value)) {
        return false;
    }

    // Check if the applicant is at least 13 years old
    const dobYear = value.year;
    const minYear = today.getFullYear() - 13;
    if (dobYear > minYear) {
        // -> born over 13 years ago
        return false;
    }
    if (dobYear === minYear) {
        const dobMonth = parseInt(value.month);
        const minMonth = today.getMonth() + 1; // "+ 1" because getMont() indexes from 0
        if (dobMonth > minMonth) {
            // -> born 13 years ago and after this month
            return false;
        }
        if (dobMonth === minMonth) {
            const dobDay = value.day;
            const minDay = today.getDate();
            if (dobDay > minDay) {
                // -> born 13 years ago, after this month and after this day
                return false;
            }
        }
    }

    return true;
}

/**
 * Checks that value is either null or a boolean
 * @param value
 */
export const validateCoapplicantIntent = (value) =>
    value === null || typeof value === "boolean";

/**
 * Checks that value is either null or "Yes" or "No"
 * @param value
 */
export const validateTradeInIntent = (value) =>
    value === null || ["Yes", "No"].includes(value);

/**
 * Checks that value is a valid non-null value for relationship_to_primary.
 * @param value
 */
export const validateRelationshipToPrimary = (value) => {
    if (!value || typeof value !== "string") {
        return false;
    }
    return !!relationshipToPrimary.find(({id}) => (id === value));
}

/*
 * Validates a payload received via post message
 * Returns true if the payload is correctly formatted otherwise returns false
 */
export function validatePayload(schema, data){
    const ajv = new Ajv();
    return ajv.validate(schema, data);
}

/*
 * Validates two urls have matching domains, including subdomains. Excludes protocols and www
 * Returns true if the domains match, otherwise false
 */
export function validateMatchingDomains(url1, url2) {
    const host1 = new URL(url1).host;
    const host2 = new URL(url2).host;
    const clean_url1 = host1.startsWith('www.') ? host1.substring(4) : host1;
    const clean_url2 = host2.startsWith('www.') ? host2.substring(4) : host2;
    if (clean_url1 === clean_url2 || clean_url1.includes('.'+clean_url2) || clean_url2.includes('.'+clean_url1)) {
        return true;
    } else {
        console.error('Mismatch in validateMatchingDomains: ' + url1 + ' and ' + url2);
        return false;
    }
}
